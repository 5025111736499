<template>
  <validation-observer ref="form" v-slot="{ invalid }" slim>
    <layout width="420">
      <template slot="header">
        {{ title }}
      </template>

      <template>
        <v-form>
          <validation-provider
            v-slot="{ errors }"
            name="Item name"
            rules="required"
          >
            <v-text-field
              v-model.trim="state.name"
              label="Item name"
              :error-messages="errors"
            >
              {{ state.name }}
            </v-text-field>
          </validation-provider>
          <v-textarea
            v-model="state.description"
            no-resize
            rows="1"
            auto-grow
            label="Description"
          >
            {{ state.description }}
          </v-textarea>
          <validation-provider
            v-slot="{ errors }"
            name="Alert count"
            :rules="maxAlertCount"
          >
            <v-text-field
              v-model="state.alertCount"
              label="Alert count"
              :error-messages="errors"
            >
              {{ state.alertCount }}
            </v-text-field>
          </validation-provider>
        </v-form>
      </template>
      <template slot="footer">
        <v-spacer />
        <v-btn text color="text-primary" @click.stop="$emit('close')">
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          depressed
          :loading="loading"
          :disabled="invalid"
          @click.stop="submit"
        >
          {{ submitBtnTitle }}
        </v-btn>
      </template>
    </layout>
  </validation-observer>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { ref, onMounted, computed } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import { geoItemService } from '@/modules/object-types/api';
import { schemasService } from '@/modules/common/api';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { maxAlertCount } from '@/utils/validation/custom-validators';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
  name: 'GeoItemEdit',
  components: {
    Layout,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    parentSchemaId: {
      type: String,
      default: ''
    },
    geoItemId: {
      type: String,
      default: ''
    }
  },
  computed: {
    maxAlertCount() {
      return maxAlertCount;
    }
  },
  setup(props, { emit }) {
    const form = ref(null);
    const geoItem = ref({});
    const state = ref({
      name: '',
      description: '',
      alertCount: 1
    });

    const edit = computed(() => props.geoItemId);
    const title = computed(() =>
      edit.value ? 'Edit geo item' : 'Add geo item'
    );
    const submitBtnTitle = computed(() => (edit.value ? 'Save' : 'Add'));
    const iconItems = ref([]);

    const handleSubmit = async () => {
      if (!(await form.value.validate())) return;
      let createdGeoItemId = props.geoItemId;
      if (!edit.value) {
        // create new geo item

        const schemaId = await geoItemService.fetchBaseGeoItemSchemaId();
        createdGeoItemId = await geoItemService.create({
          name: state.value.name,
          description: state.value.description,
          schemaId,
          parentSchemaId: props.parentSchemaId
        });
        geoItem.value = await geoItemService.fetch(createdGeoItemId);
      } else {
        await geoItemService.update(props.geoItemId, {
          name: state.value.name,
          description: state.value.description
        });
        // edit new state
      }
      await schemasService.updateValues(
        [
          {
            id: geoItem.value.commonAlertCount.id,
            value: Number(state.value.alertCount)
          },
          {
            id: geoItem.value.infoName.id,
            value: state.value.name
          }
        ],
        createdGeoItemId
      );
      emit('close');
    };

    const { loading, exec: submit } = usePromise(handleSubmit, true);

    onMounted(async () => {
      if (edit.value) {
        geoItem.value = await geoItemService.fetch(props.geoItemId);
        state.value = {
          name: geoItem.value.infoName.value,
          description: geoItem.value.description || '',
          alertCount: geoItem.value.commonAlertCount.value
        };
      }
    });

    return {
      state,
      loading,
      title,
      submitBtnTitle,
      submit,
      edit,
      iconItems,
      form
    };
  }
};
</script>
